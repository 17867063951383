.logo {
  max-height: 25px;
}

.navbar {
  background: #fff;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #fff, #fff);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #fff, #fff);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-image: linear-gradient(to right, #fff, #fff);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

#about-btn {
  background: rgba(255, 255, 255, 0.5);
  border: rgba(255, 255, 255, 0.5);
}

@media (min-width: 310px) {
  .logo {
    max-height: 30px;
  }
}

@media (min-width: 350px) {
  .logo {
    max-height: 35px;
  }
}

.div-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.div-wrapper .ddd {
  position: absolute;
  left: 0;
  bottom: 0;
}

.audioElements__svgBtnDefault___3VrwT:nth-child(1) {
  display: none !important;
}

.audioElements__svgBtnDefault___3VrwT:nth-child(3) {
  display: none !important;
}

.audioElements__svgBtnDefault___3VrwT:nth-child(4) {
  display: none !important;
}

.audioComponents__flexButtonBox___hwvgN {
  display: flex !important;
  justify-content: flex-start !important;
}

.audioElements__albumCoverContainer___E0mbQ {
  display: none !important;
}

.audioElements__commentsWrapper___Gf4GH
  > .audioElements__commentsWrapperBackground___3avoO {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-size: cover !important;
  background-position: center center !important;
  -webkit-filter: blur(0px) !important;
  filter: blur(0px) !important;
  z-index: 0 !important;
}

.audioElements__timeLine___6OG0r {
  margin-top: 30px !important;
}

.audioElements__commentsWrapper___Gf4GH
  > .audioElements__commentsWrapperBackgroundMask___3LMXR {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(28, 28, 28, 0) !important;
  z-index: 5;
}

.navbar {
  height: 80px !important;
}

.navbar-brand {
  height: 80% !important;
}

.logo {
  max-height: 100% !important;
  min-height: 100% !important;
}
