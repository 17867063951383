@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap);
body {
  overflow-y: scroll;
  background: #ffffff !important;
  font-family: "Montserrat", sans-serif;
}

.bg-warnings {
  background-color: #c1272d !important;
  color: white !important;
}

.alert-info {
  color: #0c5460;
  background-color: LIGHTGRAY !important;
  border-color: #bee5eb;
}

.btn-info {
  color: #fff;
  background-color: #c1272d !important;
  border-color: #c1272d !important;
}

#about-btn {
  color: white;
  background: #c1272d !important;
  border: #c1272d !important;
}

.aaa {
  color: #c1272d;
  text-decoration: none;
  background-color: transparent;
}

.btn-light {
  color: #ffffff !important;
  background-color: #c1272d !important;
  border-color: #c1272d !important;
}

.text-primarys {
  color: #c1272d !important;
  font-size: larger;
  font-weight: bolder;
}

.text-infos {
  color: #c1272d !important;
  font-weight: bolder;
}

.mmmm {
  background-color: gray !important;
}

.nnnn {
  background-color: #fff !important;
}

#install-banner {
  z-index: 1;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: #ffffff;
  box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

@media (min-width: 768px) {
  #install-banner {
    position: fixed;
    bottom: 10px;
    right: 10px;
    left: auto;
    border-radius: 30px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
}

@media (max-height: 500px) {
  #install-banner {
    display: none;
  }
}

#list-card {
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.logo {
  max-height: 25px;
}

.navbar {
  background: #fff;
  /* fallback for old browsers */
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #fff, #fff);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-image: linear-gradient(to right, #fff, #fff);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

#about-btn {
  background: rgba(255, 255, 255, 0.5);
  border: rgba(255, 255, 255, 0.5);
}

@media (min-width: 310px) {
  .logo {
    max-height: 30px;
  }
}

@media (min-width: 350px) {
  .logo {
    max-height: 35px;
  }
}

.div-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.div-wrapper .ddd {
  position: absolute;
  left: 0;
  bottom: 0;
}

.audioElements__svgBtnDefault___3VrwT:nth-child(1) {
  display: none !important;
}

.audioElements__svgBtnDefault___3VrwT:nth-child(3) {
  display: none !important;
}

.audioElements__svgBtnDefault___3VrwT:nth-child(4) {
  display: none !important;
}

.audioComponents__flexButtonBox___hwvgN {
  display: flex !important;
  justify-content: flex-start !important;
}

.audioElements__albumCoverContainer___E0mbQ {
  display: none !important;
}

.audioElements__commentsWrapper___Gf4GH
  > .audioElements__commentsWrapperBackground___3avoO {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-size: cover !important;
  background-position: center center !important;
  -webkit-filter: blur(0px) !important;
  filter: blur(0px) !important;
  z-index: 0 !important;
}

.audioElements__timeLine___6OG0r {
  margin-top: 30px !important;
}

.audioElements__commentsWrapper___Gf4GH
  > .audioElements__commentsWrapperBackgroundMask___3LMXR {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(28, 28, 28, 0) !important;
  z-index: 5;
}

.navbar {
  height: 80px !important;
}

.navbar-brand {
  height: 80% !important;
}

.logo {
  max-height: 100% !important;
  min-height: 100% !important;
}

/* TODO: style for print */

@media print {
  @page {
    size: A4;
    margin-bottom: 8mm;
  }
  body {
    font-size: 16pt;
  }
  .col-print-4 {
    width: 33%;
    float: left;
  }
  .col-print-11 {
    width: 90.75%;
    float: left;
  }
}
@media only screen and (max-width: 600px) {
  
#poli{
  flex-direction: column;

}
}

.column-left {
  float: left;
  width: 33.333%;
}

.column-right {
  float: right;
  width: 33.333%;
}

.column-center {
  display: inline-block;
  width: 33.333%;
}

.content {
  clear: both;
  margin-top: 50px;
}

@media print {
  @page {
    size: A4;
  }
  body {
    font-size: 18pt;
    background: #ffffff !important;
  }
}

.bg {
  background: #fff;
  /* fallback for old browsers */
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #fff, #fff);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-image: linear-gradient(to right, #fff, #fff);
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
  display: block;
}

.content {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
}

.my-card {
  background-color: rgba(255, 255, 255, 0.8);
  /*box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);*/
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 5px;
}

